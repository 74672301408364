import React, { useEffect } from "react";
import "./index.css";
import Home from "./routes/Home";
import { Projects } from "./routes/Projects";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Lenis from 'lenis';
import { Route, Routes } from "react-router-dom";

function App() {
  useEffect(() => {
    const lenis = new Lenis();

    // Handle scroll events
    lenis.on('scroll', (e) => {
      console.log(e); // You can handle scroll events here
    });

    // Animation frame for Lenis
    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);

    // Cleanup on component unmount
    return () => {
      lenis.destroy(); // Destroy Lenis instance to avoid memory leaks
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
