import pro1 from "../assets/project1.png";
import pro2 from "../assets/project2.png";
import pro3 from "../assets/project3.png";




const  ProjectCardData = [
  {
    imgsrc: pro1,
    title: "Java-Currency Program",
    text: "At the conclusion of our first semester, We developed a currency conversion program. The application allows users to input an amount, select the currency they are converting from and to, and then calculates the final converted amount. Additionally,the program provides a breakdown of the transaction,including applicable commission fees and the total amount the user will receive.",
    view: "https://github.com/muratmustafaozmn/Java---Currency-program.git",
  },
  {
    imgsrc: pro2,
    title: "My first Python Discord Bot",
    text: "I developed a Discord bot that welcomes new users both in the General chat and via direct message, and sends a goodbye message when they leave the server.",
    view: "https://github.com/muratmustafaozmn/Python-welcomer-discord-bot.git",
  },
  {
    imgsrc: pro3,
    title: "Arduino Hangman Project",
    text: "During the first semester, I collaborated with a group to develop a Hangman project using Arduino",
    view: "https://www.tinkercad.com/things/aGM7pNBOQyw-hangman-project",
  },
  {
    imgsrc: pro2,
    title: "Another Project",
    text: "Another Project",
    view: "https://www.youtube.com/watch?v=P8YuWEkTeuE",
  },  
  {
    imgsrc: pro2,
    title: "Another Project",
    text: "Another Project",
    view: "https://www.youtube.com/watch?v=P8YuWEkTeuE",
  },  
  {
    imgsrc: pro2,
    title: "Rymo Ecommerce Design",
    text: "Another Project",
    view: "https://www.youtube.com/watch?v=P8YuWEkTeuE",
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  },
];

export default ProjectCardData;
