import "./AboutContentStyles.css";

import React from "react";
import { Link } from "react-router-dom";
import React1 from "../assets/react1.jpg";


const AboutContent = () => {
  return (
    <div className="about">
      <div className="left">
        <h1>Who Am I?</h1>
        <p> 
          Hi there! I’m Murat Mustafa, a computer programming enthusiast currently studying in my third
          semester at Georgian College. I have a strong interest in a variety of programming languages and technologies,
          including React, Python, Java, SQL, C++, and C#. I’m passionate about creating dynamic web applications and diving
          into complex problem-solving tasks. Whether it's developing interactive UIs with React or optimizing database queries
          with SQL, I enjoy the challenge of bringing innovative ideas to life through code.
        </p>
        <Link to="/contact">
          <button className="btn">Contact</button>
        </Link>
      </div>

      <div className="right">
        <div className="img-container">
          <div className="img-stack top">
            <img src={React1} className="img" alt="true" />
          </div>
          <div className="img-stack bottom">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
