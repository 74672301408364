import "./HeroimgStyles.css";
import React from 'react';

import IntroImg from "../assets/yellow.jpg"; // Keep this line as is
import { Link } from "react-router-dom";

const Heroimg = () => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="intro-img" src={IntroImg} alt="IntroImg" />
      </div>
      
      <div className="content">
        <h1>Hello, I'm Murat Mustafa</h1>
        <p>I'm Murat Mustafa, a third-semester Computer Programming student showcasing my work in software development.</p>
        <div>
          <Link to="/projects" className="btn">Projects</Link>
          <Link to="/contact" className="btn btn-light">Contact</Link>
        </div>
      </div>
    </div>
  );
};

export default Heroimg;
