import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";



import Work from "../components/Work";

const Projects = () => {
  return (
  
    <div>
      <Navbar />  
      <Work />   
      <Footer />
    </div>
  );
};

export {Projects};
