import "./FormStyles.css";
import React, { useState } from "react";
import emailjs from "emailjs-com"; // Make sure to install emailjs-com

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [sent, setSent] = useState(false); // Track if the message has been sent

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Send email using EmailJS
    emailjs.send('service_1e1dv4c', 'template_4q8e0o8', formData, 'eUihz47-13khlPmG4')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSent(true); // Set sent to true on success
        setTimeout(() => setSent(false), 5000); // Optional: Hide message after 5 seconds
      }, (err) => {
        console.error('FAILED...', err);
      });
    
    // Reset form fields
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <label>Your Name</label>
        <input 
          type="text" 
          name="name" 
          value={formData.name} 
          onChange={handleChange}
          required 
        />
        <label>Email</label>
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleChange}
          required 
        />
        <label>Subject</label>
        <input 
          type="text" 
          name="subject" 
          value={formData.subject} 
          onChange={handleChange}
          required 
        />
        <label>Message</label>
        <textarea 
          rows="6" 
          name="message" 
          value={formData.message} 
          onChange={handleChange}
          placeholder="Type your message here" 
          required 
        />
        <button className="btn" type="submit">Submit</button>

        {/* Move the success message under the submit button */}
        {sent && <p style={{ color: 'green', marginTop: '1rem' }}>Your message has been sent!</p>}
      </form>
    </div>
  );
};

export default Form;
