import "./FooterStyles.css";

import React from "react";

import {
  FaHome,
  FaMailBulk,
  FaLinkedin,
  FaGithub,
  
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <div>
              <p>Toronto, ON</p>
              
            </div>
          </div>
          <div className="phone">
           
          </div>
          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#fff", marginRight: "2rem" }}
              />
              muratozmenbb@gmail.com
            </h4>
          </div>
        </div>

        <div className="right">
          <h4>About me</h4>
          <p>
          Hello, I’m Murat Mustafa, a computer programming student in my third semester at Georgian College, 
          continuously working on improving my skills and knowledge.
          </p>
          <div className="social">
          <a href="https://www.linkedin.com/in/muratmustafaozmen/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={30} style={{ color: "#fff", marginRight: "1rem" }}/>
          </a>
          </div>
          <div className="social2">
          <a href="https://github.com/muratmustafaozmn" target="_blank" rel="noopener noreferrer">
            <FaGithub size={30} style={{ color: "#fff", marginRight: "1rem" }}/>
          </a>
</div>

        </div>
      </div>
    </div>
  );
};

export default Footer;