import React from 'react';
import Navbar from '../components/Navbar';
import HeroImg from '../components/Heroimg';
import Footer from '../components/Footer';


const Home = () => {
  return (
    <div>
        <Navbar />
        <HeroImg />
        <Footer />
    </div>
  );
};

export default Home;
